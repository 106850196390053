/**
 * Created by osirvent on 30/11/2015.
 */
angular
    .module('annexaApp')
    .config(['$stateProvider', function($stateProvider) {
        $stateProvider
            .state('annexa.doc', {
                abstract: true,
                url: '/doc',
                views: {
                    "": {
                        templateUrl: './views/layout/doc.html'
                    }
                }
            })
            .state('annexa.doc.documents', {
                redirectTo: ['$rootScope', function($rootScope) {
                    var fromState = $rootScope.previousState;
                    var fromParams = $rootScope.previousStateParams;

                    if(fromState && fromState.name && fromParams && fromParams.type) {
                        return {
                            state: 'annexa.doc.documents.type',
                            params: { type: fromParams.type }
                        }
                    } else {
                        return 'annexa.doc.documents.all';
                    }
                }],
                url: '/documents',
                views: {
                    "maindoc@annexa.doc": {
                        controller: 'DocumentsController',
                        templateUrl: './views/layout/documents_list.html'
                    }
                },
                data: {
                    title: 'global.documents.toptitle',
                    displayName: 'global.literals.documents',
                    authenticate: true,
                    permissions: {
                        only: 'view_documents'
                    }
                },
                resolve: {
                    getDocumentList: ['DccumentsFactory', function (DccumentsFactory) {
                        return DccumentsFactory.getDocumentLists();
                    }]
                }
            })
            .state('annexa.doc.documents.all',{
                url: '/all/:status/:type',
                params: {
                    status: { value: null, squash: true },
                    type: { value: null, squash: true },
					filterId: { value: null, squash:true }
                },
                views: {
                    "maindoclist@annexa.doc.documents": {
                        controller: 'DocumentsAllController',
                        templateUrl: './views/layout/all.html'
                    }
                },
                data: {
                    title: 'global.documents.toptitle',
                    displayName: 'global.literals.all',
                    authenticate: true,
                    permissions: {
                        only: 'view_documents'
                    }
                },
                resolve: {
                    setSelectedType: ['DccumentsFactory', function (DccumentsFactory) {
                        DccumentsFactory.setSelectedType();
                        return true;
                    }],
                    setSelectedDocumentStatus: ['DccumentsFactory', '$stateParams', function (DccumentsFactory, $stateParams) {
                        var selectedStatus = undefined;

                        if($stateParams && $stateParams.status) {
                            selectedStatus = $stateParams.status;
                        }

                        DccumentsFactory.setSelectedDocumentStatus(selectedStatus);
                    }]
                }
            })
            .state('annexa.doc.documents.type', {
                url: '/type/:type',
				params:{
					type:{ value: null, squash: true },
					filterId: { value: null, squash: true }
				},
                views: {
                    "maindoclist@annexa.doc.documents": {
                        controller: 'DocumentsTypeController',
                        templateUrl: './views/layout/by_type.html'
                    }
                },
                resolve: {
                    resolveDocumentsTypeType: ['DccumentsFactory', '$stateParams', '$q', '$timeout', function (DccumentsFactory, $stateParams, $q, $timeout) {
                        if($stateParams.type) {
                            return  DccumentsFactory.getDocumentsTypeType($stateParams.type);
                        } else {
                            $timeout(function() { $state.go('annexa.dashboard'); });
                            return $q.reject("No document type");
                        }
                    }],
                    setSelectedType: ['DccumentsFactory', '$stateParams', '$rootScope', function (DccumentsFactory, $stateParams, $rootScope) {
                        if($stateParams && $stateParams.type) {
                            DccumentsFactory.setSelectedType($stateParams.type);
                        }
                        return true;
                    }],
                    setSelectedDocumentStatus: ['DccumentsFactory', '$stateParams', function (DccumentsFactory, $stateParams) {
                        DccumentsFactory.setSelectedDocumentStatus();
                    }]
                }
            })
            .state('annexa.doc.documents.view', {
                url: '/view/:code',
                views: {
                    "maindoclist@annexa.doc.documents": {
                        controller: 'DocumentViewController',
                        templateUrl: './views/layout/document.html'
                    }
                },
                data: {
                    title: 'global.documents.toptitle',
                    displayName: 'global.documents.seeDocument',
                    authenticate: true,
                    permissions: {
                        only: 'view_documents'
                    }
                },
                resolve:{
                    getDocument: ['DccumentsFactory', '$stateParams', function (DccumentsFactory, $stateParams) {
                        return DccumentsFactory.getDocument($stateParams.code);
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }]
                }
            });
    }]);